@tailwind base;

@layer base {
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  a {
    color: 'blue', @apply underline;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  outline: none !important;
}

.columns-node-view > div {
  display: flex;
  flex: 1;
  gap: 16px;
}

.node-card {
  flex: 1 0 0;
  height: 100%;
}

ol ul,
ol ol,
ul ol,
ul ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

li p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

li .drag-handle {
  display: none;
}

.table > div {
  width: 100%;
  display: table;
}
